import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "../services/language.service";
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'timePast',
  standalone: true
})
export class TimePastPipe implements PipeTransform {

  constructor(
    private languageService: LanguageService,
    private datePipe: DatePipe
  ) {
  }

  transform(value: Date, ...args: unknown[]): string|null {
    const now = new Date();
    const inputDate = new Date(value);
    const diffInMilliseconds = now.getTime() - inputDate.getTime();
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));

    if (diffInMinutes < 1) return this.languageService.getTranslationByKey('RIGHTNOW');
    if (diffInMinutes < 2) return this.languageService.getTranslationByKey('AMINUTEAGO');
    if (diffInMinutes < 60) return this.languageService.getTranslationByKey('SOMEMINUTESAGO').replace('{SMA}', String(diffInMinutes));
    if (diffInHours < 2) return this.languageService.getTranslationByKey('AHOURAGO');
    if (diffInHours < 24) return this.languageService.getTranslationByKey('SOMEHOURSAGO').replace('{SHA}', String(diffInHours));

    return this.datePipe.transform(value, 'short');
  }

}
