<div class="e-avatar e-avatar-circle e-avatar-xsmall {{ border ? 'e-avatar-border' : ''}} m-1" *ngIf="type === 'xxs'">
  <ng-container *ngIf="image">
    <img [ngSrc]="image" [width]="24" [height]="24" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
  </ng-container>
  <ng-container *ngIf="!image">
    <span class="mts-icon p-m">{{ icon }}</span>
  </ng-container>
</div>

<div class="e-avatar e-avatar-circle e-avatar-small {{ border ? 'e-avatar-border' : ''}}" *ngIf="type === 'small'">
  <ng-container *ngIf="image">
    <img [ngSrc]="image" [width]="24" [height]="24" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
  </ng-container>
  <ng-container *ngIf="!image">
    <span class="mts-icon p-m">{{ icon }}</span>
  </ng-container>
</div>

<div class="e-avatar e-avatar-circle e-avatar-small {{ border ? 'e-avatar-border' : ''}}" *ngIf="type === 'medium'">
  <ng-container *ngIf="image">
    <img [ngSrc]="image" [width]="40" [height]="40" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
  </ng-container>
  <ng-container *ngIf="!image">
    <span class="mts-icon p-m fs-4">{{ icon }}</span>
  </ng-container>
</div>

<div class="e-avatar e-avatar-circle e-avatar-large {{ border ? 'e-avatar-border' : ''}}" *ngIf="type === 'large'">
  <ng-container *ngIf="image">
    <img [ngSrc]="image" [width]="60" [height]="60" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
  </ng-container>
  <ng-container *ngIf="!image">
    <span class="mts-icon p-m fs-4">{{ icon }}</span>
  </ng-container>
</div>

<div class="e-avatar e-avatar-circle e-avatar-xxlarge {{ border ? 'e-avatar-border' : ''}}" *ngIf="type === 'xxl'">
  <ng-container *ngIf="image">
    <img [ngSrc]="image" [width]="60" [height]="60" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
  </ng-container>
  <ng-container *ngIf="!image">
    <span class="mts-icon p-m fs-4">{{ icon }}</span>
  </ng-container>
</div>

<div class="d-flex align-items-center" *ngIf="type === 'default'">
  <div class="e-avatar e-avatar-circle e-avatar-large {{ border ? 'e-avatar-border' : ''}} m-1">
    <ng-container *ngIf="image">
      <img [ngSrc]="image" [width]="60" [height]="60" [sizes]="'100vw, 100vw, 100vw'" [alt]="name" priority="low" />
    </ng-container>
    <ng-container *ngIf="!image">
      <span class="mts-icon p-m fs-4">{{ icon }}</span>
    </ng-container>
  </div>
  <div>
    <app-headline [type]="'h6'" [title]="name" [class]="'text-primary fs-small'"></app-headline>
    <app-content [style]="'small'" [cssClass]="'py-s'">{{ subTitle }}</app-content>
    <app-content [style]="'small'" [cssClass]="'py-s'">{{ date | timePast }}</app-content>
  </div>
</div>
