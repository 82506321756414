import {Component, Input} from '@angular/core';
import {ContentComponent} from "../content/content.component";
import {HeadlineComponent} from "../headline/headline.component";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {TimePastPipe} from "../../pipes/time-past.pipe";

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [
    ContentComponent,
    HeadlineComponent,
    NgOptimizedImage,
    TimePastPipe,
    NgIf
  ],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
  @Input() image: string = '';
  @Input() name: string = '';
  @Input() subTitle: string = '';
  @Input() date: Date = new Date();
  @Input() type: 'xxs' | 'small' | 'medium' | 'large' | 'xxl' | 'default' = 'default';
  @Input() icon: 'person' | 'group' | 'translate' | 'mail' = 'person';
  @Input() border: boolean = true;

}
